 import axios from 'axios';

const state = {
    looptijdenData: {},
    quotiteitenData: {}
};

const getters = {
    getLooptijdenData() {
        return state.looptijdenData;
    },
    
    getQuotiteitInterest: state => (id, looptijd, quotiteit) => {
        let qId = 'Q0';
        if (quotiteit > 90) qId = 'Q90';
        if (quotiteit > 85 && quotiteit <= 90) qId = 'Q85';
        if (quotiteit > 75 && quotiteit <= 85) qId = 'Q80';
        
        let interest = 6.0;
        if (state.quotiteitenData[id]) {
            if (state.quotiteitenData[id][looptijd]) {
                if (state.quotiteitenData[id][looptijd][qId]) {
                    interest = state.quotiteitenData[id][looptijd][qId]["intrestvoet"];
                }
            }
        }
        return interest;
    }
};

const actions = {
    async fetchQuotiteitInterestData({ commit, rootState }) {
        await axios.get(rootState.platformUrl + "/api/intresten")
            .then(response => {
                commit('SET_QUOTITEITEN_DATA', response.data);
            })
            .catch(error => {
                console.log("Error fetching interests from JSON", error);
            });
    }
};

const mutations = {
    SET_LOOPTIJDEN_DATA(state, payload) {
        state.looptijdenData = payload;
    },
    
    SET_QUOTITEITEN_DATA(state, payload) {
        state.quotiteitenData = payload;
    }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
