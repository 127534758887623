const defaults = {
    error: false,
    historySteps: [1],
    stepJumps: {
        5: 7,
        7: 9
    }, // default voor 'aankoop'
    
    // Aankoop
    leningReden: "aankoop",
    lopendeKredieten: false,
    blijvendeKredietlasten: 0,
    krediet: {
        type: "hypotheek",
        openstaandeSchuld: 0,
        maandlast: 0
    },
    aankoopprijs: 0,
    teLenenBedrag: 0,
    kostenLenen: "gedeeltelijk", // ja // nee
    aantalOntleners: 1, // 1 of 2 -> labels: alleen OF met 2
    persoon: {
        voornaam: "",
        achternaam: "",
        geboortedatum: null,
        nettoInkomen: 0,
        maaltijdcheques: false,
        dienstjaren: 10
    },
    looptijd: 20,
    huurinkomsten: 0,
    andereInkomsten: 0,
    email: "",
    telefoon: "",
    contactWijze: "eender", // eender // email // tel // videocall
    kinderen: 0,
    
    // Aankoop + werken
    renovatieKost: 0,
    
    // Herschikking, Herfinancieren & Echtscheiding
    extraTeLenenBedrag: 0,
    extraLeningReden: "",
    waardeWoning: 0,
    
    //Echtscheiding
    uitTeBetalenBedrag: 0,
    
    // Nieuwbouw
    aankoopprijsGrond: 0,
    kostprijsConstructie: 0,
    
    // Investeringskrediet
    investeringsKredietReden: "lr",
    ondernemersnummer: "" // xxxx.xxx.xxx
};

const state = {
    // Static data
    leningRedenen: [
        {
            id: "aankoop",
            label: "Aankoop"
        },
        {
            id: "aankoop-werken",
            label: "Aankoop + werken"
        },
        {
            id: "herschikking",
            label: "Herschikking"
        },
        {
            id: "herfinanciering",
            label: "Herfinanciering"
        },
        {
            id: "echtscheiding",
            label: "Echtscheiding"
        },
        {
            id: "btw",
            label: "Nieuwbouw + BTW"
        },
        {
            id: "investeringskrediet",
            label: "Investeringskrediet"
        }
    ],
    kredietTypes: [
        {
            id: "hypotheek",
            label: "Hypotheek"
        },
        {
            id: "afbetaling",
            label: "Lening op afbetaling"
        },
        {
            id: "krediet",
            label: "Kredietopening"
        }
    ],
    kredietRedenen: [
        {
            id: "werken",
            label: "Werken"
        },
        {
            id: "liquiditeiten",
            label: "Liquiditeiten"
        },
        {
            id: "huwelijk",
            label: "Huwelijk"
        },
        {
            id: "onverdeeldheid",
            label: "Uit onverdeeldheid"
        },
        {
            id: "belastingen",
            label: "Belastingen"
        },
        {
            id: "schulden",
            label: "Schulden aan derden"
        },
        {
            id: "aankopen",
            label: "Aankopen"
        },
        {
            id: "persoonlijk",
            label: "Persoonlijk"
        }
    ],
    investeringsKredietRedenen: [
        {
            id: "lr",
            label: "Leasing/Renting"
        },
        {
            id: "werken",
            label: "Werken"
        },
        {
            id: "herschikken",
            label: "Herschikken kredieten"
        },
        {
            id: "onroerend",
            label: "Nieuwe investering in onroerend goed"
        },
        {
            id: "roerend",
            label: "Nieuwe investering in roerende goederen"
        },
        {
            id: "btw",
            label: "Btw/belastingen"
        },
        {
            id: "liquiditeiten",
            label: "Liquiditeiten"
        },
        {
            id: "aanzuiveringen",
            label: "Aanzuiveringen"
        },
        {
            id: "andere",
            label: "Andere..."
        }
    ],
    
    // Dynamic data
    error: defaults.error,
    historySteps: [...defaults.historySteps],
    stepJumps: { ...defaults.stepJumps },
    aanvraag: {
        leningReden: defaults.leningReden,
        lopendeKredieten: defaults.lopendeKredieten,
        blijvendeKredietlasten: defaults.blijvendeKredietlasten,
        kredieten: [{ ...defaults.krediet }],
        persoon1: { ...defaults.persoon },
        persoon2: { ...defaults.persoon },
        aankoopprijs: defaults.aankoopprijs,
        teLenenBedrag: defaults.teLenenBedrag,
        kostenLenen: defaults.kostenLenen,
        aantalOntleners: defaults.aantalOntleners,
        looptijd: defaults.looptijd,
        huurinkomsten: defaults.huurinkomsten,
        andereInkomsten: defaults.andereInkomsten,
        email: defaults.email,
        telefoon: defaults.telefoon,
        contactWijze: defaults.contactWijze,
        kinderen: defaults.kinderen,
        renovatieKost: defaults.renovatieKost,
        extraTeLenenBedrag: defaults.extraTeLenenBedrag,
        extraLeningReden: defaults.extraLeningReden,
        uitTeBetalenBedrag: defaults.uitTeBetalenBedrag,
        aankoopprijsGrond: defaults.aankoopprijsGrond,
        kostprijsConstructie: defaults.kostprijsConstructie,
        waardeWoning: defaults.waardeWoning,
        investeringsKredietReden: defaults.investeringsKredietReden,
        ondernemersnummer: defaults.ondernemersnummer
    }
};

const actions = {
    updateError({ commit }, payload) {
        commit("SET_ERROR", payload);
    },
    
    addKrediet({ commit }, payload) {
        commit("ADD_KREDIET", payload);
    },
    
    removeKrediet({ commit }, payload) {
        commit("REMOVE_KREDIET", payload);
    },
    
    updateAanvraag({ commit }, payload) {
        commit("SET_AANVRAAG", payload);
    },
    
    resetStepJump({ commit }, payload) {
        commit("RESET_STEP_JUMP", payload);
    },
    
    resetForm({ commit }, payload) {
        commit("RESET_FORM", payload);
        commit("RESET_HISTORY", payload);
    },
    
    resetFormButStayOnResult({ commit }, payload) {
        commit("RESET_FORM", payload);
    }
};

const mutations = {
    SET_ERROR(state, payload) {
        state.error = payload;
    },
    
    ADD_KREDIET(state) {
        state.aanvraag.kredieten.push({ ...defaults.krediet });
    },
    
    REMOVE_KREDIET(state, payload) {
        if (state.aanvraag.kredieten[payload] != null) {
            state.aanvraag.kredieten.splice(payload, 1);
        }
    },
    
    SET_AANVRAAG(state, payload) {
        state.aanvraag = payload;
    },
    
    RESET_STEP_JUMP(state) {
        state.stepJumps = { ...{} };
    },
    
    RESET_FORM(state) {
        // Default values
        state.error = defaults.error;
        state.stepJumps = { ...defaults.stepJumps };
        state.aanvraag.leningReden = defaults.leningReden;
        state.aanvraag.lopendeKredieten = defaults.lopendeKredieten;
        state.aanvraag.blijvendeKredietlasten = defaults.blijvendeKredietlasten;
        state.aanvraag.kredieten = [{ ...defaults.krediet }];
        state.aanvraag.persoon1 = { ...defaults.persoon };
        state.aanvraag.persoon2 = { ...defaults.persoon };
        state.aanvraag.aankoopprijs = defaults.aankoopprijs;
        state.aanvraag.teLenenBedrag = defaults.teLenenBedrag;
        state.aanvraag.kostenLenen = defaults.kostenLenen;
        state.aanvraag.aantalOntleners = defaults.aantalOntleners;
        state.aanvraag.looptijd = defaults.looptijd;
        state.aanvraag.huurinkomsten = defaults.huurinkomsten;
        state.aanvraag.andereInkomsten = defaults.andereInkomsten;
        state.aanvraag.email = defaults.email;
        state.aanvraag.telefoon = defaults.telefoon;
        state.aanvraag.contactWijze = defaults.contactWijze;
        state.aanvraag.kinderen = defaults.kinderen;
        state.aanvraag.renovatieKost = defaults.renovatieKost;
        state.aanvraag.extraTeLenenBedrag = defaults.extraTeLenenBedrag;
        state.aanvraag.extraLeningReden = defaults.extraLeningReden;
        state.aanvraag.uitTeBetalenBedrag = defaults.uitTeBetalenBedrag;
        state.aanvraag.aankoopprijsGrond = defaults.aankoopprijsGrond;
        state.aanvraag.kostprijsConstructie = defaults.kostprijsConstructie;
        state.aanvraag.investeringsKredietReden = defaults.investeringsKredietReden;
        state.aanvraag.waardeWoning = defaults.waardeWoning;
        state.aanvraag.ondernemersnummer = defaults.ondernemersnummer;
    },
    
    RESET_HISTORY(state) {
        state.historySteps = [...defaults.historySteps];
    }
};

const getters = {
    getKredieten: state => {
        return state.aanvraag.kredieten;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
