import 'promise-polyfill/src/polyfill';
import objectFitImages from 'object-fit-images';

import store from "./store/index.js";

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import SlideUpDown from 'vue-slide-up-down';
import VModal from 'vue-js-modal';
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import Datepicker from 'vuejs-datepicker';
import { nl } from 'vuejs-datepicker/dist/locale';
import { TimePicker } from 'ant-design-vue';
import lottie from 'lottie-web';

// Import our CSS
import styles from '../css/app.pcss';

// App main
const main = async () => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    const { default: axios } = await import( /* webpackChunkName: "axios" */ 'axios');
    const VueCookies = await import( /* webpackChunkName: "vue-cookies" */ 'vue-cookies');
    
    Vue.use(VueCookies);
    Vue.use(VModal);
    Vue.use(TimePicker);
    /*Vue.use(DatePicker);*/
    Vue.component('slide-up-down', SlideUpDown);
    Vue.prototype.axios = axios;
    window.axios = axios;
    
    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        store,
        delimiters: ['${', '}'],
        
        components: {
            Datepicker,
            'calc-lenen': () => import(/* webpackChunkName: "calc-lenen" */ '../vue/calc-lenen/CalcLenen.vue'),
            'calc-kosten': () => import(/* webpackChunkName: "calc-kosten" */ '../vue/calc-kosten/CalcKosten.vue'),
            'calc-aflossing': () => import(/* webpackChunkName: "calc-aflossing" */ '../vue/calc-aflossing/CalcAflossing.vue'),
            'calc-aflossingstabel': () => import(/* webpackChunkName: "calc-aflossingstabel" */ '../vue/calc-aflossingstabel/CalcAflossingstabel.vue'),
            'calc-kredieten': () => import(/* webpackChunkName: "calc-kredieten" */ '../vue/calc-kredieten/CalcKredieten.vue'),
            'calc-aanvraag': () => import(/* webpackChunkName: "calc-aanvraag" */ '../vue/calc-aanvraag/CalcAanvraag.vue')
        },
        
        data: {
            mobileNavIsOpen: false,
            isMobile: true,
    
            // Cookie consent
            showCookieConsent: false,
            showGeneralNotice: true,
    
            // Navigation
            mastheadHeight: 0,
            fixateNav: false,
            lastScrollTop: 0,
            hideMasthead: false,
    
            // News page
            page: 1,
            articlesLoadedCount: 0,
            totalCount: 0,
            initialCount: 0,
            sequentialCount: 0,
            loadingEntries: false,
            allLoaded: false,
            
            // Video-embed component
            modalIsOpen: false,
            videoModalEmbedCode: '',
            videoModalAspectRatio: '',
            vimeoUrl: '',
            
            // Testimonial
            testimonialInterval: null,
            
            // Appointment creation form
            appointmentDate: null,
            appointmentTime: "",
            disabledAppointmentDates: {
                to: new Date(),
                days: [6, 0] // Disable Saturday's and Sunday's
            },
            nl: nl,
            hours: ['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
            minutes: ['00', '15', '30', '45'],
        },
    
        created() {
            window.addEventListener('scroll', this.onWindowScroll);
            window.addEventListener('resize', this.onWindowResize);
            
            // Fetch interestData for calculators
            this.$store.dispatch("excelFormulas/fetchQuotiteitInterestData");
        },
    
        beforeDestroy() {
            window.removeEventListener('scroll', this.onWindowScroll);
            window.removeEventListener('resize', this.onWindowResize);
        },
    
        mounted() {
            window.isMounted = true;
            this.checkIsMobile();
            
            /* Show cookie consent with delay */
            setTimeout(() => {
                vm.showCookieConsent = true;
            }, 600);
    
            // Navigation
            let masthead = document.getElementById('masthead');
            //let mastheadPaddingBottom = window.getComputedStyle(masthead).getPropertyValue('padding-bottom');
            this.mastheadHeight = masthead.clientHeight;
            this.onWindowScroll();
            this.onWindowResize();
    
            // News
            if (this.$refs.newsEntries) {
                this.totalCount = parseInt(this.$refs.newsEntries.dataset.totalCount, 10);
                this.initialCount = parseInt(this.$refs.newsEntries.dataset.initialCount, 10);
                this.sequentialCount = parseInt(this.$refs.newsEntries.dataset.sequentialCount, 10);
            }
            this.articlesLoadedCount = this.initialCount;
            this.allLoaded = this.totalCount <= this.articlesLoadedCount;
            
            // Thumbnail animations
            let svgAnimContainers = Array.from(document.getElementsByClassName('snippet__thumbnail__svg'));
            svgAnimContainers.forEach(container => {
                let id = container.getAttribute('id');
                console.log(container);
                this.loadThumbnailAnimation(id, container);
            });
            
            // Testimonials
            this.initClientMaps();
            
            // General notice
            const cookieGeneralNotification = VueCookies.get('iFincoHideGeneralNotification');
            if(cookieGeneralNotification) this.showGeneralNotice = !VueCookies.get('iFincoHideGeneralNotification') === 'confirmed';
            
        },
        
        methods: {
            onWindowResize() {
                this.checkIsMobile();
                this.initClientMaps();
            },
            
            onWindowScroll() {
                if (!this.mobileNavIsOpen) {
                    this.fixateNav = window.pageYOffset > 10;
    
                    let scrollTop = window.pageYOffset;
                    this.hideMasthead = (scrollTop > this.lastScrollTop) && (scrollTop > this.mastheadHeight);
    
                    this.lastScrollTop = scrollTop;
                }
            },
    
            openNav(){
                this.mobileNavIsOpen = !this.mobileNavIsOpen;
                this.mobileNavIsOpen ? disableBodyScroll(this.$refs.navigationLinks) : enableBodyScroll(this.$refs.navigationLinks);
            },
    
            confirmCookieConsent() {
                let vm = this;
        
                VueCookies.set('iFincoCookieConsent', 'confirmed', "1y");
                setTimeout(() => {
                    vm.showCookieConsent = false;
                }, 200);
            },
    
            initClientMaps() {
                // Get all client-map-blocks on the page (could be multiple)
                clearInterval(this.testimonialInterval);
                
                let clientMaps = document.getElementsByClassName('content-block--client-map');
                if(clientMaps.length > 0) {
                    Array.from(clientMaps).forEach(block => {
                        let testimonials = block.getElementsByClassName('testimonials')[0];
                        let largestHeight = 0;
            
                        // The testimonials div doesn't have height on mobile as the children are absolutely positioned
                        // So we check the largest height of the children...
                        Array.from(testimonials.children).forEach(testimonial => {
                            largestHeight = testimonial.clientHeight > largestHeight ? testimonial.clientHeight : largestHeight;
                        });
            
                        // ...and set the height accordingly
                        if(window.matchMedia("(max-width: 767px)").matches) {
                            testimonials.style.height = largestHeight + 'px';
                        } else {
                            testimonials.style.height = '35%';
                        }
            
                        // Swap between testimonials
                        let testimonialCount = testimonials.children.length;
                        let currentTestimonial = 0;
            
                        if (testimonialCount > 1) {
                            this.testimonialInterval = setInterval(() => {
                                Array.from(testimonials.children).forEach(el => {
                                    el.classList.remove('active');
                                });
                                /*testimonials.children[currentTestimonial].classList.remove('active');*/
                                currentTestimonial = currentTestimonial === testimonialCount - 1 ? 0 : currentTestimonial + 1;
                                testimonials.children[currentTestimonial].classList.add('active');
                            }, 5000);
                        }
                    });
                }
            },
    
            loadMoreArticles() {
                let vm = this;
        
                // Load more btn was clicked so we're loading entries and we're hiding the button and showing the loader
                this.loadingEntries = true;
        
                axios.get("ajax/news/p" + this.page + "?initialCount=" + this.initialCount + "&sequentialCount=" + this.sequentialCount)
                    .then(function (response) {
                        // Get element to append articles to
                        let parent = vm.$refs.newsEntries;
                
                        // Append loaded articles at the end of the element
                        parent.insertAdjacentHTML('beforeend', response.data);
                        
                        // Set next pagination page
                        vm.page++;
                
                        // Update our article loaded count
                        vm.articlesLoadedCount += vm.sequentialCount;
                
                        // Show the load more articles button again
                        vm.loadingEntries = false;
                
                        // Fade in new elements (set timeout because else it doesn't work properly)
                        setTimeout(function () {
                            for (const el of Array.from(document.getElementsByClassName('newly-loaded'))) {
                                el.classList.remove("opacity-0");
                            }
                        }, 100);
                
                
                        // Check if all articles are loaded to hide the load more button
                        if (vm.articlesLoadedCount >= vm.totalCount) {
                            vm.allLoaded = true;
                        }
                    })
                    .catch(function (error) {
                        //console.log("error fetching articles", error);
                    });
            },
    
            checkIsMobile() {
                this.isMobile = window.matchMedia("(max-width: 1000px)").matches;
            },
    
            closeModal(name) {
                this.$modal.hide(name);
            },
            
            beforeModalClosed() {
                this.modalIsOpen = false;
                this.modalIsOpen ? disableBodyScroll(this.$refs.modal) : enableBodyScroll(this.$refs.modal);
            },
    
            showModalVideo(embedCode, aspectRatio){
                this.$modal.show('modal-video', { embedCode: embedCode });
                this.videoModalAspectRatio = aspectRatio;
                this.modalIsOpen = true;
                this.modalIsOpen ? disableBodyScroll(this.$refs.modal) : enableBodyScroll(this.$refs.modal);
            },
    
            beforeModalVideoOpen(event){
                this.videoModalEmbedCode = event.params.embedCode;
            },
    
            getVideoThumb(videoSource, embedCode){
                let vm = this;
                let url = '';
        
                if (videoSource === 'youtube'){
            
                    // Get the URL from the embed code
                    let videoUrl = embedCode.match(/\bhttps?:\/\/\S+/gi).toString();
            
                    // Strip optional trailing slash from URL
                    videoUrl = videoUrl.endsWith('/') ? videoUrl.slice(0, -1) : videoUrl;
            
                    // Get the YouTube video ID (last segment) from the URL and remove any queryStrings
                    let youtubeId = videoUrl.toString().replace('"', '').split("/").pop().split("?")[0];
            
                    // Compose the YouTube thumbnail URL
                    url = 'https://img.youtube.com/vi/' + youtubeId + '/maxresdefault.jpg';
            
                } else if (videoSource === 'vimeo'){
            
                    // Get the first URL from the embed code
                    let videoUrl = embedCode.match(/\bhttps?:\/\/\S+/gi)[0].toString();
            
                    // Strip optional trailing slash from URL
                    videoUrl = videoUrl.endsWith('/') ? videoUrl.slice(0, -1) : videoUrl;
                    videoUrl = videoUrl.replace('"', '');
            
                    // Get JSON data from the Vimeo URL
                    axios.get("https://vimeo.com/api/oembed.json?url=" + videoUrl)
                        .then(response => {
                            vm.vimeoUrl = response.data.thumbnail_url;
                        })
                        .catch(error => {
                            console.log("error fetching Vimeo JSON", error);
                        });
            
                    url = this.vimeoUrl
                }
        
                return url;
            },
    
            flipCard(e){
                let card = e.target.closest(".snippet__content"); // No IE11 support but is for mobile only
                if(card.classList.contains('flipped')){
                    card.classList.remove('flipped');
                } else {
                    card.classList.add('flipped');
                }
                
            },
    
            dateToString(dateObject){
                return dateObject ? `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}` : 'Geen voorkeur';
            },
    
            timeToString(dateObject){
                return dateObject ? dateObject.format('HH:mm') : 'Geen voorkeur';
            },
    
            loadThumbnailAnimation(name, container){
                lottie.loadAnimation({
                    container: container, // the dom element that will contain the animation
                    renderer: 'svg', // canvas
                    loop: true,
                    autoplay: true,
                    path: '/assets/svg-anims/' + name + '.json' // the path to the animation json
                });
            },
    
            closeGeneralNotification() {
                const cookieConsentGiven = VueCookies.get('iFincoCookieConsent') === 'confirmed';
                if (cookieConsentGiven) VueCookies.set('iFincoHideGeneralNotification', 'confirmed', 0);
                this.showGeneralNotice = false;
            }
        },
        
        computed: {
            getVideoModalAspectRatio(){
                return 'content-block--embed-' + this.videoModalAspectRatio;
            },
    
            getTimes(){
                let arrTimes = [];
        
                let h = 0;
                for(h; h < this.hours.length; h++){
                    let m = 0;
                    for(m; m < this.minutes.length; m++){
                        if(h === this.hours.length - 1 && m > 0){
                            break;
                        }
                        arrTimes.push(this.hours[h] + ':' + this.minutes[m]);
                    }
                }
        
                return arrTimes;
            }
        }
    });

    return vm;
};

// Execute async function
main().then( (vm) => {
    // IE11 object-fit polyfill
    objectFitImages();
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
