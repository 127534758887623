const NEXT_STEP = (state, payload) => {
    state[payload.moduleName].historySteps.push(payload.nextStep);
};

const PREVIOUS_STEP = (state, payload) => {
    let h = state[payload.moduleName].historySteps;
    h.pop();
    state[payload.moduleName].currentStep = h[h.length - 1];
};

const ADD_STEP_JUMP = (state, payload) => {
    state[payload.moduleName].stepJumps[payload.jumpFrom] = payload.jumpTo;
};

const REMOVE_STEP_JUMP = (state, payload) => {
    delete state[payload.moduleName].stepJumps[payload.key];
};

export default {
    NEXT_STEP,
    PREVIOUS_STEP,
    ADD_STEP_JUMP,
    REMOVE_STEP_JUMP
}
