const defaults = {
    historySteps: [1],
    aantalOntleners: 1,
    nettoInkomen1: 0,
    nettoInkomen2: 0,
    maaltijdcheques: false,
    aantalKinderen: 0,
    huurinkomsten: 0,
    andereInkomsten: 0
};

const state = {
    historySteps: [...defaults.historySteps],
    aantalOntleners: defaults.aantalOntleners,
    nettoInkomen1: defaults.nettoInkomen1,
    nettoInkomen2: defaults.nettoInkomen2,
    maaltijdcheques: defaults.maaltijdcheques,
    aantalKinderen: defaults.aantalKinderen,
    huurinkomsten: defaults.huurinkomsten,
    andereInkomsten: defaults.andereInkomsten
};

const actions = {
    updateAantalOntleners({ commit }, payload) {
        commit("SET_AANTAL_ONTLENERS", payload);
    },
    
    updateNettoInkomen1({ commit }, payload) {
        commit("SET_NETTO_INKOMEN_1", payload);
    },
    
    updateNettoInkomen2({ commit }, payload) {
        commit("SET_NETTO_INKOMEN_2", payload);
    },
    
    updateMaaltijdcheques({ commit }, payload) {
        commit("SET_MAALTIJDCHEQUES", payload);
    },
    
    updateAantalKinderen({ commit }, payload) {
        commit("SET_AANTAL_KINDEREN", payload);
    },
    
    updateHuurinkomsten({ commit }, payload) {
        commit("SET_HUURINKOMSTEN", payload);
    },
    
    updateAndereInkomsten({ commit }, payload) {
        commit("SET_ANDERE_INKOMSTEN", payload);
    },
    
    resetForm({ commit }, payload) {
        commit("RESET_FORM", payload);
    }
};

const mutations = {
    SET_AANTAL_ONTLENERS(state, payload) {
        state.aantalOntleners = payload;
    },
    
    SET_NETTO_INKOMEN_1(state, payload) {
        state.nettoInkomen1 = payload;
    },
    
    SET_NETTO_INKOMEN_2(state, payload) {
        state.nettoInkomen2 = payload;
    },
    
    SET_MAALTIJDCHEQUES(state, payload) {
        state.maaltijdcheques = payload;
    },
    
    SET_AANTAL_KINDEREN(state, payload) {
        state.aantalKinderen = payload;
    },
    
    SET_HUURINKOMSTEN(state, payload) {
        state.huurinkomsten = payload;
    },
    
    SET_ANDERE_INKOMSTEN(state, payload) {
        state.andereInkomsten = payload;
    },
    
    RESET_FORM(state) {
        // Default values
        state.historySteps = [...defaults.historySteps];
        state.aantalOntleners = defaults.aantalOntleners;
        state.nettoInkomen1 = defaults.nettoInkomen1;
        state.nettoInkomen2 = defaults.nettoInkomen2;
        state.maaltijdcheques = defaults.maaltijdcheques;
        state.aantalKinderen = defaults.aantalKinderen;
        state.huurinkomsten = defaults.huurinkomsten;
        state.andereInkomsten = defaults.andereInkomsten;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
