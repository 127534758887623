const defaults = {
    historySteps: [1],
    krediet: {
        openstaandeSchuld: 0,
        maandlast: 0
    },
    waardeHuis: 0,
    nettoInkomen: 0,
    notarisKostenLenen: true,
    looptijd: 15
};

const state = {
    historySteps: [...defaults.historySteps],
    kredieten: [{...defaults.krediet}],
    waardeHuis: defaults.waardeHuis,
    nettoInkomen: defaults.nettoInkomen,
    notarisKostenLenen: defaults.notarisKostenLenen,
    looptijd: defaults.looptijd,
    interest: 4 // No default as once data is loaded this is always calculated
};

const actions = {
    addKrediet({ commit }, payload) {
        commit("ADD_KREDIET", payload);
    },
    
    removeKrediet({ commit }, payload) {
        commit("REMOVE_KREDIET", payload);
    },
    
    updateKredieten({ commit }, payload) {
        commit("UPDATE_KREDIETEN", payload);
    },
    
    updateWaardeHuis({ commit }, payload) {
        commit("SET_WAARDE_HUIS", payload);
    },
    
    updateNettoInkomen({ commit }, payload) {
        commit("SET_NETTO_INKOMEN", payload);
    },
    
    updateNotarisKostenLenen({ commit }, payload) {
        commit("SET_NOTARIS_KOSTEN_LENEN", payload);
    },
    
    updateLooptijd({ commit }, payload) {
        commit("SET_LOOPTIJD", payload);
    },
    
    updateInterest({ commit }, payload) {
        commit("SET_INTEREST", payload);
    },
    
    resetForm({ commit }, payload) {
        commit("RESET_FORM", payload);
    }
};

const mutations = {
    ADD_KREDIET(state) {
        state.kredieten.push({...defaults.krediet});
    },
    
    REMOVE_KREDIET(state, payload) {
        if (state.kredieten[payload] != null) {
            state.kredieten.splice(payload, 1);
        }
    },
    
    UPDATE_KREDIETEN(state, payload) {
        state.kredieten = payload;
    },
    
    SET_WAARDE_HUIS(state, payload) {
        state.waardeHuis = payload;
    },
    
    SET_NETTO_INKOMEN(state, payload) {
        state.nettoInkomen = payload;
    },
    
    SET_NOTARIS_KOSTEN_LENEN(state, payload) {
        state.notarisKostenLenen = payload;
    },
    
    SET_LOOPTIJD(state, payload) {
        state.looptijd = payload;
    },
    
    SET_INTEREST(state, payload) {
        state.interest = payload;
    },
    
    RESET_FORM(state) {
        // Default values
        state.historySteps = [...defaults.historySteps];
        state.kredieten = [{...defaults.krediet}];
        state.waardeHuis = defaults.waardeHuis;
        state.nettoInkomen = defaults.nettoInkomen;
        state.notarisKostenLenen = defaults.notarisKostenLenen;
        state.looptijd = defaults.looptijd;
    }
};

const getters = {
    getKredieten: state => {
        return state.kredieten;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
