const defaults = {
    historySteps: [1],
    mensualiteit: 0,
    looptijd: 15
};

const state = {
    historySteps: [...defaults.historySteps],
    mensualiteit: defaults.mensualiteit,
    looptijd: defaults.looptijd
};

const actions = {
    updateMensualiteit({ commit }, payload) {
        commit("SET_MENSUALITEIT", payload);
    },
    
    updateLooptijd({ commit }, payload) {
        commit("SET_LOOPTIJD", payload);
    },
    
    resetForm({ commit }, payload) {
        commit("RESET_FORM", payload);
    }
};

const mutations = {
    SET_MENSUALITEIT(state, payload) {
        state.mensualiteit = payload;
    },
    
    SET_LOOPTIJD(state, payload) {
        state.looptijd = payload;
    },
    
    RESET_FORM(state) {
        // Default values
        state.historySteps = [...defaults.historySteps];
        state.mensualiteit = defaults.mensualiteit;
        state.looptijd = defaults.looptijd;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
