const defaults = {
    historySteps: [1],
    teFinancierenBedrag: 100000,
    looptijd: 20,
    interest: 1.5,
    afbetalingType: 'vast' // OF kapitaal
};

const state = {
    historySteps: [...defaults.historySteps],
    teFinancierenBedrag: defaults.teFinancierenBedrag,
    looptijd: defaults.looptijd,
    interest: defaults.interest,
    afbetalingType: defaults.afbetalingType
};

const actions = {
    updateTeFinancierenBedrag({ commit }, payload) {
        commit("SET_TE_FINANCIEREN_BEDRAG", payload);
    },
    
    updateLooptijd({ commit }, payload) {
        commit("SET_LOOPTIJD", payload);
    },
    
    updateInterest({ commit }, payload) {
        commit("SET_INTEREST", payload);
    },
    
    updateAfbetalingType({ commit }, payload) {
        commit("SET_AFBETALING_TYPE", payload);
    },
    
    resetForm({ commit }, payload) {
        commit("RESET_FORM", payload);
    }
};

const mutations = {
    SET_TE_FINANCIEREN_BEDRAG(state, payload) {
        state.teFinancierenBedrag = payload;
    },
    
    SET_LOOPTIJD(state, payload) {
        state.looptijd = payload;
    },
    
    SET_INTEREST(state, payload) {
        state.interest = payload;
    },
    
    SET_AFBETALING_TYPE(state, payload) {
        state.afbetalingType = payload;
    },
    
    RESET_FORM(state) {
        // Default values
        state.historySteps = [...defaults.historySteps];
        state.teFinancierenBedrag = defaults.teFinancierenBedrag;
        state.looptijd = defaults.looptijd;
        state.interest = defaults.interest;
        state.afbetalingType = defaults.afbetalingType;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
