const defaults = {
    historySteps: [1],
    kostenType: 'aankoop', // OF herfinanciering
    aankoopprijs: 0,
    ontleendeBedrag: 0,
    uniekeWoning: 'kb',
    gewest: 'vlaanderen',
    abbatement: false,
    stepJumps: { 4: 6 } // Default voor Vlaams gewest
};

const state = {
    historySteps: [...defaults.historySteps],
    kostenType: defaults.kostenType,
    aankoopprijs: defaults.aankoopprijs,
    ontleendeBedrag: defaults.ontleendeBedrag,
    uniekeWoning: defaults.uniekeWoning,
    gewest: defaults.gewest,
    abbatement: defaults.abbatement,
    stepJumps: {...defaults.stepJumps},
};

const actions = {
    updateKostenType({ commit }, payload) {
        commit("SET_KOSTEN_TYPE", payload);
    },
    
    updateAankoopprijs({ commit }, payload) {
        commit("SET_AANKOOPPRIJS", payload);
    },
    
    updateOntleendeBedrag({ commit }, payload) {
        commit("SET_ONTLEENDE_BEDRAG", payload);
    },
    
    updateUniekeWoning({ commit }, payload) {
        commit("SET_UNIEKE_WONING", payload);
    },
    
    updateGewest({ commit }, payload) {
        commit("SET_GEWEST", payload);
    },
    
    updateAbbatement({ commit }, payload) {
        commit("SET_ABBATEMENT", payload);
    },
    
    addStepJumps({ commit }, payload) {
        commit("ADD_STEP_JUMPS", payload);
    },
    
    removeStepJumps({ commit }, payload) {
        commit("REMOVE_STEP_JUMPS", payload);
    },
    
    resetForm({ commit }, payload) {
        commit("RESET_FORM", payload);
    }
};

const mutations = {
    SET_KOSTEN_TYPE(state, payload) {
        state.kostenType = payload;
    },
    
    SET_AANKOOPPRIJS(state, payload) {
        state.aankoopprijs = payload;
    },
    
    SET_ONTLEENDE_BEDRAG(state, payload) {
        state.ontleendeBedrag = payload;
    },
    
    SET_UNIEKE_WONING(state, payload) {
        state.uniekeWoning = payload;
    },
    
    SET_GEWEST(state, payload) {
        state.gewest = payload;
    },
    
    SET_ABBATEMENT(state, payload) {
        state.abbatement = payload;
    },
    
    RESET_FORM(state) {
        // Default values
        state.historySteps = [...defaults.historySteps];
        state.kostenType = defaults.kostenType;
        state.aankoopprijs = defaults.aankoopprijs;
        state.ontleendeBedrag = defaults.ontleendeBedrag;
        state.uniekeWoning = defaults.uniekeWoning;
        state.gewest = defaults.gewest;
        state.abbatement = defaults.abbatement;
        state.stepJumps = {...defaults.stepJumps};
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
