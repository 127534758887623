import Vue                  from "vue";
import Vuex                 from "vuex";
import actions              from './actions.js';
import mutations            from './mutations.js';
import excelFormulas        from './modules/excel-formulas';
import calcLenen            from './modules/calc-lenen.js';
import calcKosten           from './modules/calc-kosten.js';
import calcAflossing        from './modules/calc-aflossing.js';
import calcAflossingstabel  from './modules/calc-aflossingstabel.js';
import calcKredieten        from './modules/calc-kredieten.js';
import calcAanvraag         from './modules/calc-aanvraag.js';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        excelFormulas,
        calcLenen,
        calcKosten,
        calcAflossing,
        calcAflossingstabel,
        calcKredieten,
        calcAanvraag
    },
    
    state: {
        platformUrl: 'https://platform.ifinco.be',
        vueSliderStyle: {
            dotSize: 20,
            width: 'auto',
            height: 10,
            contained: true,
            direction: 'ltr',
            clickable: true,
            duration: 0.5,
            adsorb: false,
            lazy: false,
            tooltip: 'always',
            tooltipPlacement: 'top',
            useKeyboard: false,
            keydownHook: null,
            dragOnClick: false,
            enableCross: true,
            fixed: false,
            dotOptions: void 0,
            process: true,
            dotStyle: void 0,
            railStyle: void 0,
            processStyle: void 0,
            tooltipStyle: void 0,
            stepStyle: void 0,
            stepActiveStyle: void 0,
            labelStyle: void 0,
            labelActiveStyle: void 0,
        },
        gewesten: [
            {
                id: "vlaanderen",
                label: "Vlaams gewest",
                registratieRechten: {
                    kb: 0.06,
                    gb: 0.10
                }
            },
            {
                id: "brussel",
                label: "Brussels gewest",
                registratieRechten: {
                    kb: 0.125,
                    gb: 0.125
                }
            },
            {
                id: "wallonie",
                label: "Waals gewest",
                registratieRechten: {
                    kb: 0.06,
                    gb: 0.125
                }
            }
        ],
        notarisKosten: [
            // [aankoopprijs, verminderd tarief, normaal tarief]
            [0, 25.50, 28.00],
            [625, 21.00, 23.50],
            [1250, 19.50, 22.00],
            [2500, 18.00, 20.50],
            [5000, 16.50, 19.00],
            [7500, 15.80, 18.00],
            [10000, 15.40, 17.50],
            [13750, 15.20, 17.10],
            [17500, 15.00, 16.80],
            [21250, 14.80, 16.50],
            [25000, 14.60, 16.30],
            [30000, 14.50, 16.10],
            [35000, 14.40, 15.90],
            [40000, 14.30, 15.70],
            [45000, 14.20, 15.50],
            [50000, 14.20, 15.40],
            [56250, 14.10, 15.30],
            [62500, 14.00, 15.20],
            [68750, 13.90, 15.10],
            [75000, 13.80, 15.00],
            [87500, 13.70, 14.80],
            [100000, 13.60, 14.60],
            [112500, 13.60, 14.40],
            [125000, 13.50, 14.20],
            [150000, 13.40, 14.10],
            [175000, 13.40, 14.00],
            [200000, 13.30, 13.90],
            [225000, 13.30, 13.80],
            [250000, 13.20, 13.70],
            [275000, 13.20, 13.60],
            [300000, 13.10, 13.50],
            [350000, 13.10, 13.40],
            [400000, 13.00, 13.40],
            [450000, 13.00, 13.30],
            [525000, 12.90, 13.20],
            [625000, 12.90, 13.10],
            [750000, 12.60, 12.70]
        ]
    },
    
    getters: {
        getCurrentStep: state => (moduleName) => {
            let h = state[moduleName].historySteps;
            return h[h.length - 1];
        },
    
        getAantalKinderenLijst: () => {
            let kinderenLijst = [];
            let max = 10;
            for (let i = 0; i <= max; i++) {
                let label = 'NO LABEL';
                if (i === 0 ) label = 'Geen kinderen';
                if (i === 1 ) label = '1 kind';
                if (i > 1 ) label = `${ i } kinderen`;
                if (i === max ) label = `${ max } of meer kinderen`;
                kinderenLijst.push({ 'id': i, 'label': label })
            }
            
            return kinderenLijst;
        }
    },
    
    actions,
    mutations,
    
    /*
    plugins: [
        createPersistedState({
            paths: [
                // Calculator Lenen
                "calcLenen.historySteps",
                "calcLenen.mensualiteit",
                "calcLenen.looptijd",
                "calcLenen.interest",
                
                // Calculator Kosten berekenen
                "calcKosten.historySteps",
                "calcKosten.stepJumps",
                "calcKosten.aankoopprijs",
                "calcKosten.beschrijf",
                "calcKosten.uniekeWoning",
                "calcKosten.gewest",
                "calcKosten.abbatement",
                
                // Calculator Aflossing berekenen
                "calcAflossing.historySteps",
                "calcAflossing.aantalOntleners",
                "calcAflossing.nettoInkomen1",
                "calcAflossing.nettoInkomen2",
                "calcAflossing.maaltijdcheques",
                "calcAflossing.aantalKinderen",
                "calcAflossing.huurInkomsten",
                "calcAflossing.andereInkomsten",
                
                // Calculator Aflossingstabel
                "calcAflossingstabel.historySteps",
                "calcAflossingstabel.teFinancierenBedrag",
                "calcAflossingstabel.looptijd",
                "calcAflossingstabel.afbetalingType",
                
                // Calculator Kredieten herschikken
                "calcKredieten.historySteps",
                "calcKredieten.kredieten",
                "calcKredieten.waardeHuis",
                "calcKredieten.nettoInkomen",
                "calcKredieten.notarisKostenLenen",
                "calcKredieten.looptijd",
                "calcKredieten.interest",
    
                // Aanvraag
                "calcAanvraag.error",
                "calcAanvraag.historySteps",
                "calcAanvraag.stepJumps",
                "calcAanvraag.aanvraag.leningReden",
                "calcAanvraag.aanvraag.lopendeKredieten",
                "calcAanvraag.aanvraag.blijvendeKredietlasten",
                "calcAanvraag.aanvraag.kredieten",
                "calcAanvraag.aanvraag.aankoopprijs",
                "calcAanvraag.aanvraag.teLenenBedrag",
                "calcAanvraag.aanvraag.kostenLenen",
                "calcAanvraag.aanvraag.aantalOntleners",
                "calcAanvraag.aanvraag.persoon1",
                "calcAanvraag.aanvraag.persoon2",
                "calcAanvraag.aanvraag.looptijd",
                "calcAanvraag.aanvraag.huurinkomsten",
                "calcAanvraag.aanvraag.andereInkomsten",
                "calcAanvraag.aanvraag.email",
                "calcAanvraag.aanvraag.telefoon",
                "calcAanvraag.aanvraag.contactWijze",
                "calcAanvraag.aanvraag.kinderen",
                "calcAanvraag.aanvraag.renovatieKost",
                "calcAanvraag.aanvraag.extraTeLenenBedrag",
                "calcAanvraag.aanvraag.extraLeningReden",
                "calcAanvraag.aanvraag.waardeWoning",
                "calcAanvraag.aanvraag.uitTeBetalenBedrag",
                "calcAanvraag.aanvraag.aankoopprijsGrond",
                "calcAanvraag.aanvraag.kostprijsConstructie",
                "calcAanvraag.aanvraag.investeringsKredietReden",
                "calcAanvraag.aanvraag.ondernemersnummer"
            ]
        })
    ]
    */
});
