const nextStep = ({ commit }, payload) => {
    commit("NEXT_STEP", payload);
};

const previousStep = ({ commit }, payload) => {
    commit("PREVIOUS_STEP", payload);
};

const addStepJump = ({ commit }, payload) => {
    commit("ADD_STEP_JUMP", payload);
};

const removeStepJump = ({ commit }, payload) => {
    commit("REMOVE_STEP_JUMP", payload);
};

export default {
    nextStep,
    previousStep,
    addStepJump,
    removeStepJump
}
